import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
  makeStyles,
  TableCellLayout,
} from "@fluentui/react-components";
import {
  Dismiss24Regular,
  NotepadPerson24Regular,
} from "@fluentui/react-icons";
import { AppSpinner } from "components/AppSpinner/AppSpinner";
import { PrimaryButton } from "components/PrimaryButton/PrimaryButton";
import { Flex } from "components/styled";
import { Form, Formik } from "formik";
import { useAtomValue } from "jotai";
import { devices } from "library/constants";
import { EPaidBy, IEmployeeView } from "library/types";
import {
  formatDateFieldForPayload,
  formatSelectFieldForPayload,
} from "library/utils";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useCreateDependentEligibility } from "state/queries/useCreateDependentEligibility";
import { useCreateEmployeeEligibility } from "state/queries/useCreateEmployeeEligibility";
import { selectedCompanyState } from "store/UIHrPage";
import styled from "styled-components";
import * as yup from "yup";
import { EligibilityFormFields } from "./EligibilityFormFields";

type Props = {
  open: boolean;
  setOpen: (state: boolean) => void;
  benefitPackageTag?: number;
  presetValues?: any;
  packageUser?: IEmployeeView;
};

export function EligibilityFormDialog({
  open,
  setOpen,
  packageUser,
  benefitPackageTag,
  presetValues = {},
}: Props) {
  const { t } = useTranslation();

  const selectedCompany = useAtomValue(selectedCompanyState);

  const { mutate: onCreateEligibility, isLoading: createInProgress } =
    useCreateEmployeeEligibility();

  const {
    mutate: onCreateDependentEligibility,
    isLoading: createDependentElInProgress,
  } = useCreateDependentEligibility();

  const styles = useStyles();

  const handleSubmit = (values) => {
    if (!packageUser) return;
    if (packageUser.isDependent) {
      onCreateDependentEligibility(
        {
          countryCode: Number(selectedCompany?.countryCode),
          clientInternalNumberGOS: Number(
            selectedCompany?.clientInternalNumberGos
          ),
          employeeEmail: packageUser?.employeeLoginEmailAddress || "",
          benefitPackageTag:
            values?.package?.value ?? values?.package?.benefitPackageTag,
          payload: {
            employeeLoginEmailAddress: packageUser?.employeeLoginEmailAddress,
            employeeDependentTag: packageUser?.employeeDependentTag,
            benefitPackageTag: values?.package?.value,
            dateOfEligibility: formatDateFieldForPayload(
              values?.dateOfEligibility
            ),
            endDateOfEligibility: formatDateFieldForPayload(
              values?.endDateOfEligibility
            ),
            cancellationPossibility: formatDateFieldForPayload(
              values?.cancellationPossibility
            ),

            //packagefields
            isAutoEnrollment: values?.isAutoEnrollment ? true : false,
            paymentMethod: formatSelectFieldForPayload({
              values,
              field: "paymentMethod",
            }),
            paymentType: formatSelectFieldForPayload({
              values,
              field: "paymentType",
            }),
            price: Number(values.price),
            usingPercentagesForPaidBy:
              values?.usingPercentagesForPaidBy === EPaidBy.Percentage,
            paidByCompany: Number(values.paidByCompany),
            paidByEmployee: Number(values.paidByEmployee),
          },
        },
        {
          onSuccess: () => {
            toast.success(t("hbh.dependentEligibilityCreated.label"));
            setOpen(false);
          },
        }
      );
      return;
    }
    onCreateEligibility(
      {
        countryCode: Number(selectedCompany?.countryCode),
        clientInternalNumberGOS: Number(
          selectedCompany?.clientInternalNumberGos
        ),
        employeeEmail: packageUser?.employeeLoginEmailAddress || "",
        payload: {
          employeeLoginEmailAddress: packageUser?.employeeLoginEmailAddress,
          benefitPackageTag:
            values?.package?.value ?? values?.package?.benefitPackageTag,
          dateOfEligibility: formatDateFieldForPayload(
            values?.dateOfEligibility
          ),
          endDateOfEligibility: formatDateFieldForPayload(
            values?.endDateOfEligibility
          ),
          cancellationPossibility: formatDateFieldForPayload(
            values?.cancellationPossibility
          ),

          //packagefields
          isAutoEnrollment: values?.isAutoEnrollment ? true : false,
          paymentMethod: formatSelectFieldForPayload({
            values,
            field: "paymentMethod",
          }),
          paymentType: formatSelectFieldForPayload({
            values,
            field: "paymentType",
          }),
          price: Number(values.price),
          usingPercentagesForPaidBy:
            values?.usingPercentagesForPaidBy === EPaidBy.Percentage,
          paidByCompany: Number(values.paidByCompany),
          paidByEmployee: Number(values.paidByEmployee),
        },
      },
      {
        onSuccess: () => {
          toast.success(t("hbh.employeeEligibilityCreated.label"));
          setOpen(false);
        },
      }
    );
  };

  const title = `${packageUser?.firstName} ${packageUser?.lastName}: Add Package`;

  const validationSchema = useMemo(() => {
    return yup.object({
      package: yup.mixed().required(),
      dateOfEligibility: yup.mixed().required(),
      endDateOfEligibility: yup.mixed().required(),

      cancellationPossibility: yup
        .mixed()
        .when(["package"], ([benefitPackage], schema) => {
          if (benefitPackage?.cancellationPossibilityDateType === 1) {
            return yup.mixed().nullable();
          }
          return yup.mixed().required();
        }),
      //packagefields
      paymentMethod: yup.mixed().required(),
      paymentType: yup.mixed().required(),
      price: yup.string().required(),
      usingPercentagesForPaidBy: yup.mixed().required(),
      paidByCompany: yup.string().required(),
      paidByEmployee: yup.string().required(),
      isAutoEnrollment: yup.boolean().required(),
    });
  }, []);

  const initialValues = { ...presetValues };

  const isLoading = createInProgress || createDependentElInProgress;

  return (
    <Dialog
      modalType="alert"
      open={open}
      onOpenChange={(event, data) => setOpen(data.open)}
    >
      <StyledDialogSurface
        style={{
          bottom: "10%",
        }}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {isLoading && <AppSpinner />}
        <Formik
          enableReinitialize
          validateOnChange={false}
          validateOnMount={false}
          validateOnBlur={true}
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={(values, actions) => {
            handleSubmit(values);
            actions.setSubmitting(false);
          }}
        >
          {({
            setValues,
            errors,

            /* and other goodies */
          }) => {
            return (
              <Form>
                <DialogBody className={styles.content}>
                  <DialogTitle
                    action={
                      <DialogTrigger action="close">
                        <Button
                          appearance="subtle"
                          aria-label="close"
                          icon={<Dismiss24Regular />}
                        />
                      </DialogTrigger>
                    }
                  >
                    <Flex width={"100%"} justify="flex-start">
                      <NotepadPerson24Regular />
                      <TableCellLayout truncate>{title}</TableCellLayout>
                    </Flex>
                  </DialogTitle>

                  <DialogContent className={styles.content}>
                    <EligibilityFormFields
                      packageUser={packageUser}
                      benefitPackageTag={benefitPackageTag}
                    />
                  </DialogContent>
                  <DialogActions>
                    <DialogTrigger disableButtonEnhancement>
                      <Button appearance="secondary">Close</Button>
                    </DialogTrigger>
                    <PrimaryButton
                      disabled={createInProgress}
                      style={{
                        paddingLeft: "25px",
                        paddingRight: "25px",
                      }}
                    >
                      {t("greco.save")}
                    </PrimaryButton>
                  </DialogActions>
                </DialogBody>
              </Form>
            );
          }}
        </Formik>
      </StyledDialogSurface>
    </Dialog>
  );
}

const StyledDialogSurface = styled(DialogSurface)`
  margin-left: 10px;
  margin-right: 10px;
  width: 95%;
  min-width: 95%;
  max-height: 98vh;
  padding: 15px;

  @media only screen and ${devices.md} {
    top: 10%;
    width: 1200px;
    min-width: 1200px;
    padding: 24px;

    margin-left: auto;
    margin-right: auto;
  }
`;

const useStyles = makeStyles({
  body: {
    display: "flex",
    flexDirection: "column",
    width: "1200px",
  },
  content: {
    maxHeight: "90vh",
    scrollbarWidth: "thin",
  },
});
