import {
  Button,
  Link,
  Menu,
  MenuButtonProps,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
  SplitButton,
  Text,
} from "@fluentui/react-components";
import { Delete16Regular, Edit16Regular } from "@fluentui/react-icons";
import { AvatarWithPreview } from "components/AvatarWithPreview/AvatarWithPreview";
import { Flex } from "components/styled";
import { useAppTheme } from "hooks/useAppTheme";
import { IClient } from "library/types";
import React, { useCallback, useState } from "react";
import { EditClientDialog } from "./EditClientDialog";
import { useTranslation } from "react-i18next";
import { ConfirmationDialog } from "components/ConfirmationDialog/ConfirmationDialog";
import { useDeleteClient } from "state/queries/useDeleteClient";
import { useSetAtom } from "jotai";
import { selectedCompanyState } from "store/UICompanyPage";
import { toast } from "react-toastify";
import { useInvalidateBenefitCategories } from "state/queries/useGetBenefitCategories";
import styled from "styled-components";

type Props = {
  client: IClient;
};

export function EditClient({ client }: Props) {
  const theme = useAppTheme();
  const { t } = useTranslation();
  const [open, setOpen] = React.useState(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);

  const invalidateBenefitCategories = useInvalidateBenefitCategories();

  const setSelectedCompany = useSetAtom(selectedCompanyState);

  const toggleDeleteConfirmationState = useCallback(() => {
    setDeleteConfirmationOpen((prev) => !prev);
  }, []);

  const onDialogOpenChange = useCallback((state) => {
    setOpen(state);
  }, []);

  const { mutate: onDeleteClient, isLoading: deleteInProgress } =
    useDeleteClient();

  const handleDeleteClient = useCallback(() => {
    onDeleteClient(
      {
        clientInternalNumberGOS: Number(client.clientInternalNumberGos),
        countryCode: Number(client.countryCode),
        rowVersion: client.rowVersion || "",
      },
      {
        onSuccess: () => {
          setSelectedCompany(null);
          invalidateBenefitCategories();
          toast.success(t("hbh.clientDeletedSuccessfully.label"));
        },
      }
    );
  }, [client]);

  return (
    <>
      {/* <Button appearance="transparent">
        <Flex
          style={{
            color: theme.palette.themePrimary,
          }}
        >
          <Link href={client?.backgroundPicturePath || ""} target="_blank">
            <AvatarWithPreview
              shape="square"
              name={client?.clientName}
              imgSrc={client?.backgroundPicturePath || ""}
            />
          </Link>
          <Flex
            onClick={(e) => {
              e.stopPropagation();
              setOpen(true);
            }}
          >
            <Edit16Regular
              style={{
                color: theme.palette.themePrimary,
              }}
            />
            {client?.clientName}
          </Flex>
        </Flex>
      </Button> */}
      <Container>
        <Menu positioning="below-end">
          <MenuTrigger disableButtonEnhancement>
            {(triggerProps: MenuButtonProps) => (
              <SplitButton
                style={{
                  paddingTop: "0 !important",
                  paddingBottom: "0 !important",
                }}
                menuButton={{
                  ...triggerProps,
                }}
                appearance="transparent"
              >
                <Flex
                  style={{
                    color: theme.palette.themePrimary,
                  }}
                >
                  <Link
                    href={client?.backgroundPicturePath || ""}
                    target="_blank"
                  >
                    <AvatarWithPreview
                      shape="square"
                      name={client?.clientName}
                      imgSrc={client?.backgroundPicturePath || ""}
                    />
                  </Link>
                  {client?.clientName}
                </Flex>
              </SplitButton>
            )}
          </MenuTrigger>

          <MenuPopover>
            <MenuList>
              <MenuItem>
                <Flex
                  onClick={(e) => {
                    e.stopPropagation();
                    setOpen(true);
                  }}
                >
                  <Edit16Regular
                    style={{
                      color: theme.palette.themePrimary,
                    }}
                  />
                  <Text>
                    {t("hbh.edit.label", {
                      client: client?.clientName,
                    })}
                  </Text>
                </Flex>
              </MenuItem>
              <MenuItem>
                <Flex
                  onClick={(e) => {
                    e.stopPropagation();
                    setDeleteConfirmationOpen(true);
                  }}
                >
                  <Delete16Regular
                    style={{
                      color: theme.palette.redDark,
                    }}
                  />
                  <Text>
                    {t("greco.delete", {
                      client: client?.clientName,
                    })}
                  </Text>
                </Flex>
              </MenuItem>
            </MenuList>
          </MenuPopover>
        </Menu>
      </Container>

      {open && (
        <EditClientDialog
          client={client}
          open={open}
          setOpen={onDialogOpenChange}
        />
      )}
      <ConfirmationDialog
        isOpen={deleteConfirmationOpen}
        toggleOpen={toggleDeleteConfirmationState}
        onConfirm={handleDeleteClient}
        confirmLabel={t("greco.delete")}
        title={`${t("hbh.deleteClient.label")}: ${client.clientName} `}
        description={t("hbh.deleteClientConfirm.label")}
      />
    </>
  );
}

const Container = styled.div`
  .fui-Button {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
  }
`;
