import {
  ChartMultipleRegular,
  CollectionsRegular,
  DataTrendingRegular,
  HistoryRegular,
  PeopleRegular,
  PeopleSettingsRegular,
  RibbonRegular,
} from "@fluentui/react-icons";
import { OverflowComponent } from "components/OverflowComponent/OverflowComponent";
import { StyledTab, StyledTabList, ToolbarContainer } from "components/styled";
import { useAtom } from "jotai";
import { EManageHrTabView, ERole } from "library/types";
import { selectedHrTabState } from "store";
import styled from "styled-components";
import { ManageEmployee } from "../ManagePageView/EmployeesView/EmployeesTable/EmployeeActions/ManageEmployee/ManageEmployee";
import { GenerateEmployeeTemplate } from "./GenerateEmployeeTemplate";
import { ImportEmployeeTemplate } from "./ImportEmployeeTemplate";
import { useTranslation } from "react-i18next";
import { useGetAuthorization } from "state/queries/useGetAuthorization";

const getTabs = ({ t, canSeeUserAssignment }) => [
  {
    value: EManageHrTabView.summary,
    name: t("hbh.summary.label"),
    icon: <ChartMultipleRegular />,
  },
  {
    value: EManageHrTabView.benefits,
    name: t("hbh.benefits.label"),

    icon: <RibbonRegular />,
  },
  {
    value: EManageHrTabView.employees,
    name: t("hbh.employees.label"),

    icon: <PeopleRegular />,
  },
  {
    value: EManageHrTabView.bulk,
    name: t("hbh.bulkManagement.label"),
    icon: <CollectionsRegular />,
  },

  {
    value: EManageHrTabView.summaryPbi,
    name: t("hbh.analytics.label"),

    icon: <DataTrendingRegular />,
  },
  {
    value: EManageHrTabView.actionHistory,
    name: t("hbh.actionHistory.label"),

    icon: <HistoryRegular />,
  },
  ...(canSeeUserAssignment
    ? [
        {
          value: EManageHrTabView.userAssignments,
          name: t("hbh.userAssignments.label"),

          icon: <PeopleSettingsRegular />,
        },
      ]
    : []),
];

export function HrPageToolbar() {
  const { t } = useTranslation();
  const { data: authorization } = useGetAuthorization();

  const canSeeUserAssignment = authorization?.roles?.every(
    (role) =>
      role !== ERole.HRCompanyAdmin && role !== ERole.ReadonlyHRCompanyAdmin
  );

  const [selectedTab, setSelectedTab] = useAtom(selectedHrTabState);

  const hasActions =
    selectedTab === EManageHrTabView.employees ||
    selectedTab === EManageHrTabView.bulk;

  const employeeTabActive = selectedTab === EManageHrTabView.employees;

  const tabs = getTabs({
    t,
    canSeeUserAssignment,
  });

  return (
    <ToolbarContainer $mobileTop={employeeTabActive ? "90px" : ""}>
      <StyledTabList
        selectedValue={selectedTab}
        onTabSelect={(e, data) => {
          setSelectedTab(data.value as EManageHrTabView);
        }}
        style={{
          overflowX: "auto",
        }}
      >
        <OverflowComponent>
          {tabs.map((tab) => {
            return (
              <StyledTab key={tab.value} icon={tab.icon} value={tab.value}>
                {tab.name}
              </StyledTab>
            );
          })}
        </OverflowComponent>
      </StyledTabList>
      {hasActions && (
        <ActionsWrap>
          <GenerateEmployeeTemplate />
          <ImportEmployeeTemplate />
          <div>
            <ManageEmployee isAddEmployee />
          </div>
        </ActionsWrap>
      )}
    </ToolbarContainer>
  );
}

const ActionsWrap = styled.div`
  display: flex;
  align-items: center;

  gap: 10px;
  justify-content: flex-end;
  flex: 1;
`;
