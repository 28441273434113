import { MUTATION_KEYS } from "library/shared";
import { IUserAssignment } from "library/types";
import { handleAxiosError } from "library/utils";
import { useMutation } from "react-query";
import api from "service/api";
import { useInvalidateGetUserAssignments } from "./useGetUserAssignments";

type Params = {
  countryCode: number | string;
  clientInternalNumberGOS: number | string;
  hbhUserAssignmentId: number | string;
  payload: IUserAssignment;
};

const updateUserAssignment = async ({
  countryCode,
  clientInternalNumberGOS,
  hbhUserAssignmentId,
  payload,
}: Params): Promise<IUserAssignment> => {
  const response = await api.post(
    `/hbhuserassignment/${countryCode}/${clientInternalNumberGOS}/${hbhUserAssignmentId}`,
    {
      ...payload,
    }
  );

  return response.data as IUserAssignment;
};

export function useUpdateUserAssignment(refetch = true) {
  const invalidateUserAssignments = useInvalidateGetUserAssignments();
  return useMutation(updateUserAssignment, {
    mutationKey: MUTATION_KEYS.updateUserAssignment,
    onSuccess: () => {
      if (refetch) {
        invalidateUserAssignments();
      }
    },
    onError: (error) => {
      // handle error
      handleAxiosError(error);
    },
  });
}
